@import "../../global.scss";

.testimonials {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 50px;

    @include mobile {
      font-size: 20px;
      overflow: hidden;
    }
  }

  .container {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @include mobile {
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }

    .card {
      width: 450px;
      height: 70%;
      border-radius: 10px;
      box-shadow: 0px 0px 15px -8px black;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 20px;
      transition: all 1s ease;

      @include mobile {
        height: 160px;
        width: 90%;
        padding: 10px;
        margin-bottom: 1em;
      }

      &.featured {
        width: 450px;
        height: 75%;
        margin: 0 30px;

        @include mobile {
          height: 160px;
          width: 90%;
          margin-bottom: 1em;
        }
      }

      &:hover {
        transform: scale(1.2);

        @include mobile {
          transform: scale(1.1);
        }
      }

      .top {
        display: flex;
        align-items: center;
        justify-content: space-around;

        img {
          &.left,
          &.right {
            height: 25px;
          }
          &.user {
            height: 250px;
            width: 250px;
            object-fit: contain;

            @include mobile {
              width: 100px;
              height: 40px;
            }
          }
        }
      }
      .center {
        padding: 10px;
        border-radius: 10px;
        background-color: rgb(204, 187, 191);

        @include mobile {
          font-size: 12px;
          padding: 5px;
        }
      }
      .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3 {
          margin-bottom: 5px;

          @include mobile {
            font-size: 14px;
          }
        }
        h4 {
          color: gray;

          @include mobile {
            font-size: 13px;
          }
        }
      }
    }
  }
}
