@import "../../global.scss";

.contact {
  background-color: white;
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  .left {
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include mobile {
      display: none;
    }

    img {
      height: 100%;
      opacity: 0.3;
      z-index: 1;
    }

    .tuts {
      z-index: 999;
      color: white;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      background-color: chocolate;
      width: 40%;
      padding: 20px;

      @include mobile {
        font-size: 14px;
        width: 80%;
        margin-top: 1em;
      }

      p {
        margin: 20px;

        @include mobile {
          margin: 10px;
          width: 100%;
        }
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 30px;
    }

    form {
      width: 70%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include mobile {
        width: 90%;
      }

      input {
        width: 300px;
        height: 30px;
        font-size: 14px;
        border-radius: 5px;
        border: 1px solid black;
        margin-top: 50px;
        padding: 5px 5px;

        &:focus {
          outline: none;
        }

        @include mobile {
          padding: 10px;
        }
      }

      textarea {
        width: 300px;
        height: 200px;
        font-size: 14px;
        margin-top: 50px;
        border-radius: 5px;
        border: 1px solid black;

        padding: 10px 20px;

        &:focus {
          outline: none;
        }

        @include mobile {
          width: 270px;
        }
      }

      button {
        width: 150px;
        height: 30px;
        color: white;
        background-color: chocolate;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        margin-top: 50px;

        &:focus {
          outline: none;
        }

        @include mobile {
          height: 100px;
        }
      }

      span {
        color: green;
      }
    }
  }
}
